// Display
.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

// Align
.items-center {
  align-items: center !important;
}

.text-center {
  text-align: center !important;
}

.text-start {
  text-align: start !important;
}

// Font
.font-bold {
  font-weight: 700 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

// Padding/Margin/Gap
$spacing-values: 0 1 2 3 4 6 8 10 12 14 16 18 20 22 24 26 30 32 33 34 36 38 40 42 44 46 48 50;

@each $value in $spacing-values {
  .m-#{$value} {
    margin: #{$value}px !important;
  }

  .mt-#{$value} {
    margin-top: #{$value}px !important;
  }

  .mb-#{$value} {
    margin-bottom: #{$value}px !important;
  }

  .gap-#{$value} {
    gap: #{$value}px !important;
  }
}
