.credential-header {
  background: radial-gradient(120% 2666.67% at 78.85% -38.19%, #00BCFF 0%, #E020E9 100%);
  color: $andes-brand-text;
  padding: 64px 20px;
  text-align: center;

  @media (min-width: $break-point-tablet) {
    margin-bottom: 0;
    padding: 70px 0;
    text-align: left;
  }

  .credential-header__container {
    position: relative;

    @media (min-width: $break-point-tablet) {
      align-items: center;
      display: flex;
      margin: 0 auto;
      max-width: 1000px;
    }
  }

  .credential-header__texts {
    margin: 0 auto;

    @media (min-width: $break-point-tablet) {
      margin: 0;
      max-width: 400px;
    }

    > h1 {
      font-weight: 800 !important;
      position: relative;
      text-transform: uppercase;

      @media (min-width: $break-point-tablet) {
        font-size: 40px !important;
      }
    }

    > p {
      margin: 16px auto 0;
      padding-bottom: 32px;

      @media (min-width: $break-point-tablet) {
        margin: 8px 0 0;
        padding-bottom: 0;
      }
    }
  }

  .credential-header__user-card {
    flex-basis: 100%;

    @media (min-width: $break-point-tablet) {
      flex-basis: 320px;
      margin-left: auto;
    }
  }
}

.credential-header--rebrand {
  background: linear-gradient(180deg, $andes-yellow-500 28%, #FFD400 100%);
}
