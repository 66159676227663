// commons
@import '../../styles/_commons';
// components
@import '../../components/credential-header/styles';
@import '../../components/credential-products/styles';
@import '../../components/user-details-card/styles';

.certificate {
  img {
    max-width: 100%;
    height: auto;
  }
}
