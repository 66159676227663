@import '~@andes/thumbnail/index';

.user-details-card {
  margin: 0 auto;
  max-width: 320px;
  padding: 0;
  width: 100%;

  @media (min-width: $break-point-tablet) {
    width: 320px;
    margin: 0;
  }

  .user-details-card__item {
    align-items: center;
    border-bottom: 1px solid $andes-gray-100;
    color: $andes-gray-900;
    display: flex;
    font-size: 14px;
    padding: 14px 16px 13px;
    text-align: left;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      align-items: flex-start;
    }

    &:not(:first-child) {
      img {
        margin-right: 12px;
      }
    }
  }

  .andes-thumbnail-container {
    margin-right: 12px;
    height: 40px;

    .andes-badge--pill {
      left: 20px !important;
      top: -16px !important;
    }
  }

  .user-details-card__avatar,
  .user-details-card__placeholder-avatar {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .user-details-card__placeholder-avatar {
    background-color: $andes-gray-150-solid;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.user-details-card--rebrand {
  .user-details-card__item:first-child {
    border: 0;
  }
}
