@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-display: swap;
  font-style: bold;
  src:
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff2') format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.woff') format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-700.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src:
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2') format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff') format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  src:
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-900.woff2') format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-900.woff') format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-900.ttf') format('truetype');
}
