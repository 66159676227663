// Libs
@import '~@andes/badge/index';
@import '~@andes/button/index';
@import '~@andes/card/index';
@import '~@andes/carousel-free/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/form/index';
@import '~@andes/message/index';
@import '~@andes/tabs/index';
@import '~@andes/textfield/index';
@import '~@andes/typography/index';

@import './_fonts';
@import './_utils';
@import './_variables';

html {
  scroll-behavior: smooth;
}

.nav-footer-mp {
  section {
    background: inherit !important;
  }
}
